<template>
  <div class="feed-body">
    <div class="feed-contents">
      <div class="w-100 d-flex flex-column">
        <span
          style="color: #6038F7; font-size: 20px; font-family: 'Poppins-Medium'"
        >
          My volunteer hours
        </span>
        <div class="mt-3">
          So far you have:
          <div class="d-flex mb-2 align-center">
            <div class="mr-2">
              <v-img
                :src="require('@/assets/svg/clock.svg')"
                width="30"
                height="30"
                contain
              />
            </div>
            {{ formatDifferent(totalHours) }} hrs
          </div>
          <div>
            <v-data-table
              :headers="headers"
              :items="data"
              :items-per-page="15"
              :loading="loading"
              class="elevation-0"
            >
              <template v-slot:[`item.hours`]="{ item }">
                {{ formatDifferent(item.minutes) }} hrs
              </template>
              <template v-slot:[`item.date`]="{ item }">
                {{ formatTime(item.start) }}
              </template>
              <template v-slot:[`item.shift`]="{ item }">
                {{ item.shift ? item.shift.title : "-" }}
              </template>
              <template v-slot:[`item.need`]="{ item }">
                <div>
                  <span
                    v-if="item.need"
                    @click="goToNeed(item.need._id)"
                    class="url-button"
                  >
                    {{ item.need.title }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </div>
              </template>
              <template v-slot:[`item.agency`]="{ item }">
                <div>
                  <span
                    v-if="item.agency"
                    @click="goToAgency(item.agency._id)"
                    class="url-button"
                  >
                    {{ item.agency.name }}
                  </span>
                  <span v-else>
                    Deleted Agency
                  </span>
                </div>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
      <v-progress-circular
        indeterminate
        color="amber"
        v-if="loading"
        class="mt-10"
      />
      <div v-else-if="data.length == 0" class="mt-10">
        No records
      </div>
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import dateFormat from "dateformat";

export default {
  components: {},
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      loadCompleted: -1,
      loadingMore: false,
      data: [],
      headers: [
        { text: "Hours", value: "hours" },
        { text: "Date", value: "date" },
        { text: "Shift", value: "shift" },
        { text: "Need", value: "need" },
        { text: "Agency", value: "agency" },
      ],
    };
  },
  methods: {
    ...mapActions("volunteer", {
      getWorkHours: "getWorkHours",
    }),
    goToAgency(id) {
      console.log(id);
      this.$router.push({
        name: "agency-profile-details",
        params: { id },
      });
    },
    goToNeed(_id) {
      this.$router.push({
        name: "agency-need-details",
        query: {
          _id,
        },
      });
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    formatDifferent(diff) {
      var hrs = Math.floor(diff / 60);
      var str = `${hrs}:`;
      // if (hrs > 9) str += `${hrs}:`;
      // else str += `0${hrs}:`;
      var mins = Math.floor(diff % 60);
      if (mins > 9) str += `${mins}`;
      else str += `0${mins}`;
      return str;
    },
    formatTime(date) {
      return dateFormat(
        this.convertTZ(date, this.timezone),
        "mm/dd/yyyy hh:MM TT"
      );
    },
    convertTZ(date, tzString) {
      if (!date) {
        return new Date(
          new Date().toLocaleString("en-US", {
            timeZone: tzString ? tzString : "America/New_York",
          })
        );
      }
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York",
          }
        )
      );
    },
  },
  computed: {
    ...mapGetters({
      timezone: "usersetting/getTimeZone",
    }),
    totalHours() {
      return this.data.reduce((a, b) => a + b.minutes, 0);
    },
  },
  mounted() {
    this.loading = true;
    this.loadCompleted = 0;
    this.getWorkHours()
      .then((res) => {
        this.loading = false;
        this.data = res;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response == undefined) {
          console.log(error);
        } else {
          this.showErrorMessage(error.response.data.message);
        }
      });
  },
};
</script>
<style scoped>
.feed-body {
  width: 100%;
  min-width: 200px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feed-contents {
  width: 100%;
  max-width: 650px;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}
</style>
