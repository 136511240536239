<template>
  <v-card @click="goToProfile" class="rounded-lg">
    <div
      class="pa-5 d-flex flex-row align-center justify-start h-100"
      style="justify-content: flex-start"
    >
      <volunteer-avatar
        :img="volunteer.midThumbnail ? volunteer.midThumbnail : volunteer.image"
        :name="volunteer.first_name + volunteer.last_name"
        :size="60"
      />
      <div class="d-flex flex-column">
        <span
          class="font-12 primary-color app-bold-font text-left text-ellipsis break-word line-height-15 force-height-30 overflow-hidden align-center py-3 ml-4"
        >
          {{ volunteer.first_name + " " + volunteer.last_name }}
        </span>
        <span
          class="gray-font-color font-12 text-left text-ellipsis break-word line-height-15 force-height-30 overflow-hidden align-center py-3 ml-4"
        >
          {{ volunteer.fanned.length }}
          {{ volunteer.fanned.length > 1 ? $t("followers") : $t("follower") }}
        </span>
      </div>
    </div>
    <v-btn
      block
      style="max-height: 40px; height: 40px;"
      :color="getFanned ? '#A1CC33' : '#472583'"
      dark
      class="rounded-b-lg"
      @click.stop="becameFan"
      :loading="loading"
    >
      {{ getFanned ? "Unfollow" : "Follow" }}
    </v-btn>
  </v-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
import VolunteerAvatar from "../VolunteerAvatar.vue";
export default {
  components: { VolunteerAvatar },
  props: {
    volunteer: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      type: "type",
    }),
    getFanned() {
      if (!this.profile.followers) return false;
      if (this.profile.followers.includes(this.volunteer._id)) return true;
      return false;
    },
  },
  methods: {
    ...mapActions("volunteer", {
      followVolunteer: "followVolunteer",
    }),
    goToProfile() {
      this.$router.push({
        name: "volunteer-profile-details",
        params: { id: this.volunteer._id },
      });
    },
    becameFan() {
      this.loading = true;
      var fanned = this.getFanned;
      this.followVolunteer({ followed: !fanned, user: this.volunteer._id })
        .then(() => {
          this.loading = false;
          // console.log(res);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.response.data.message);
        });
    },
  },
};
</script>
<style></style>
